<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('bsri_report.mill_disbursement_report') }}</h4>
          </template>
          <template v-slot:body>
            <b-row>
              <b-col lg="12" sm="12">
                <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                  <b-form @submit.prevent="handleSubmit(searchData)" @reset.prevent="reset" >
                    <b-row>
                      <b-col lg="6" md="6" sm="12">
                          <ValidationProvider name="Fiscal Year" vid="fiscal_year_id" rules="required|min_value:1">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="fiscal_year_id"
                                  slot-scope="{ valid, errors }"
                                  >
                                  <template v-slot:label>
                                  {{$t('elearning_config.fiscal_year') }} <span class="text-danger">*</span>
                                  </template>
                                  <b-form-select
                                  id="fiscal_year_id"
                                  plain
                                  v-model="search.fiscal_year_id"
                                  :options="fiscalYearList"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  >
                                  <template v-slot:first>
                                      <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                  </template>
                                  </b-form-select>
                                  <div class="invalid-feedback">
                                  {{ errors[0] }}
                                  </div>
                              </b-form-group>
                          </ValidationProvider>
                      </b-col>
                      <b-col lg="6" md="6" sm="12">
                        <ValidationProvider name="Mill Info" vid="mill_info_id">
                            <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="mill_info_id"
                              >
                            <template v-slot:label>
                              {{$t('bsri_demandDIS.mill_name')}}
                              </template>
                              <b-form-select
                                id="mill_info_id"
                                plain
                                v-model="search.mill_info_id"
                                :options="millInfoList"
                                >
                                <template v-slot:first>
                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                                </b-form-group>
                          </ValidationProvider>
                        </b-col>
                      <b-col sm="12">
                        <b-button type="submit" variant="primary float-right" class="mr-2">{{ $t('globalTrans.search') }}</b-button>
                      </b-col>
                    </b-row>
                  </b-form>
                </ValidationObserver>
              </b-col>
            </b-row>
          </template>
        </iq-card>
        <b-row >
          <b-col md="12" v-if="showData">
            <b-overlay>
              <iq-card>
                <template v-slot:body>
                  <b-row>
                    <b-col md="12">
                      <iq-card>
                        <template v-slot:headerTitle>
                          <h4 class="card-title">{{ $t('bsri_report.mill_disbursement_report') }}</h4>
                        </template>
                        <template v-slot:headerAction>
                          <b-button class="btn_add_new" @click="pdfExport">
                            <i class="fas fa-print"></i>{{  $t('globalTrans.print') }}
                          </b-button>
                        </template>
                        <template v-slot:body>
                          <b-overlay :show="loading">
                            <b-row mt-5>
                              <b-col md="12" class="table-responsive">
                                <div style="border: 2px solid;margin:10px;padding:10px">
                                  <b-row>
                                    <b-col>
                                      <list-report-head :base-url="incentiveGrantServiceBaseUrl" uri="/common-config/report-heading/detail" :org-id="8">
                                        <template v-slot:projectNameSlot>
                                          {{ }}
                                        </template>
                                     <span style="text-decoration: underline;">{{ $t('bsri_report.mill_disbursement_report') }}</span>
                                      </list-report-head>
                                    </b-col>
                                  </b-row>
                                  <div class="text-black">
                                    <b-row>
                                      <b-col><strong>{{ $t('elearning_config.fiscal_year') }}: </strong>{{ search.fiscal_year_id ? ($i18n.locale === 'en' ? searchResulttwo.fiscal_year : searchResulttwo.fiscal_year_bn) : $t('globalTrans.all')  }}</b-col>
                                      <b-col><strong>{{ $t('bsri_demandDIS.mill_name') }}: </strong>{{ search.mill_info_id  ?  ($i18n.locale === 'en' ? searchResulttwo.mill_name : searchResulttwo.mill_name_bn) : $t('globalTrans.all') }}</b-col>
                                      <b-col></b-col>
                                      <b-col></b-col>
                                    </b-row>
                                  </div>
                                  <b-row>
                                    <b-col>
                                      <b-table-simple class="tg mt-3" bordered striped hover small caption-top responsive>
                                          <b-thead>
                                              <b-tr>
                                                  <b-th rowspan="4" style="text-align: center;vertical-align: middle;">{{ $t('globalTrans.sl_no') }}</b-th>
                                                  <b-th rowspan="4" style="text-align: center;vertical-align: middle;">{{ $t('bsri_report.mill_name') }}</b-th>
                                                  <b-th rowspan="4" style="text-align: center;vertical-align: middle;">{{ $t('bsri_report.total_farmers') }}</b-th>
                                                  <b-th colspan="10" style="text-align: center;vertical-align: middle;">{{ $t('bsri_report.Cultivation_method_and_subsidy_text') }}</b-th>
                                              </b-tr>
                                              <b-tr>
                                                  <b-th colspan="2" style="text-align: center;vertical-align: middle;">{{ $t('bsri_report.Cultivation_Ropa_Cane_And_sathi_Crops_Text') }}</b-th>
                                                  <b-th colspan="2" style="text-align: center;vertical-align: middle;">{{ $t('bsri_report.Production_OfCane_Seed_In_Ropa_Method_text') }}</b-th>
                                                  <b-th colspan="2" style="text-align: center;vertical-align: middle;">{{ $t('bsri_report.Cultivation_of_Ropa_Cane') }}</b-th>
                                                  <b-th colspan="2" style="text-align: center;vertical-align: middle;">{{ $t('bsri_report.Cultivation_Of_Cane_In_Muri_Method') }}</b-th>
                                                  <b-th colspan="2" style="text-align: center;vertical-align: middle;">{{ $t('bsri_report.In_total') }}</b-th>
                                              </b-tr>
                                              <b-tr>
                                                  <b-th style="text-align: center;vertical-align: middle;">{{ $t('bsri_report.land_quantity') }}</b-th>
                                                  <b-th style="text-align: center;vertical-align: middle;">{{ $t('bsri_report.amount') }}</b-th>
                                                  <b-th style="text-align: center;vertical-align: middle;">{{ $t('bsri_report.land_quantity') }}</b-th>
                                                  <b-th style="text-align: center;vertical-align: middle;">{{ $t('bsri_report.amount') }}</b-th>
                                                  <b-th style="text-align: center;vertical-align: middle;">{{ $t('bsri_report.land_quantity') }}</b-th>
                                                  <b-th style="text-align: center;vertical-align: middle;">{{ $t('bsri_report.amount') }}</b-th>
                                                  <b-th style="text-align: center;vertical-align: middle;">{{ $t('bsri_report.land_quantity') }}</b-th>
                                                  <b-th style="text-align: center;vertical-align: middle;">{{ $t('bsri_report.amount') }}</b-th>
                                                  <b-th style="text-align: center;vertical-align: middle;">{{ $t('bsri_report.Total_land_quantity') }}</b-th>
                                                  <b-th style="text-align: center;vertical-align: middle;">{{ $t('bsri_report.Method_all_amount') }}</b-th>
                                              </b-tr>
                                          </b-thead>
                                          <b-tbody v-if="childshowdata">
                                            <template v-if="(searchResult.length > 0)">
                                              <b-tr v-for="(info, index3) in searchResult" :key="index3">
                                                 <b-th class="notbold">{{$n((index3 + 1))}}</b-th>
                                                 <b-th class="notbold">{{ $i18n.locale === 'bn' ? info.mill_name_bn : info.mill_name }}</b-th>
                                                 <b-th class="notbold">{{$n(info.total_farmers)}}</b-th>
                                                 <b-th class="notbold">{{$n(parseFloat(info.Method_one_quantity).toFixed(2))}}</b-th>
                                                 <b-th class="notbold">{{$n(parseFloat(info.Method_one_amount).toFixed(2))}}</b-th>
                                                 <b-th class="notbold">{{$n(parseFloat(info.Method_two_quantity).toFixed(2))}}</b-th>
                                                 <b-th class="notbold">{{$n(parseFloat(info.Method_two_amount).toFixed(2))}}</b-th>
                                                 <b-th class="notbold">{{$n(parseFloat(info.Method_three_quantity).toFixed(2))}}</b-th>
                                                 <b-th class="notbold">{{$n(parseFloat(info.Method_three_amount).toFixed(2))}}</b-th>
                                                 <b-th class="notbold">{{$n(parseFloat(info.Method_four_quantity).toFixed(2))}}</b-th>
                                                 <b-th class="notbold">{{$n(parseFloat(info.Method_four_amount).toFixed(2))}}</b-th>
                                                 <b-th class="notbold">{{$n(parseFloat(info.Total_land_quantity).toFixed(2))}}</b-th>
                                                 <b-th class="notbold">{{$n(parseFloat(info.Method_all_amount).toFixed(2))}}</b-th>
                                              </b-tr>
                                              <b-tr v-for="(info, index3) in totalResult" :key="index3">
                                                 <b-th class="notbold"></b-th>
                                                 <b-th class="notbold">{{ $t('globalTrans.total') }}</b-th>
                                                 <b-th class="notbold">{{$n(info.total_farmers)}}</b-th>
                                                 <b-th class="notbold">{{$n(info.One_quantity.toFixed(2))}}</b-th>
                                                 <b-th class="notbold">{{$n(info.One_amount.toFixed(2))}}</b-th>
                                                 <b-th class="notbold">{{$n(info.Two_quantity.toFixed(2))}}</b-th>
                                                 <b-th class="notbold">{{$n(info.Two_amount.toFixed(2))}}</b-th>
                                                 <b-th class="notbold">{{$n(info.Three_quantity.toFixed(2))}}</b-th>
                                                 <b-th class="notbold">{{$n(info.Three_amount.toFixed(2))}}</b-th>
                                                 <b-th class="notbold">{{$n(info.Four_quantity.toFixed(2))}}</b-th>
                                                 <b-th class="notbold">{{$n(info.Four_amount.toFixed(2))}}</b-th>
                                                 <b-th class="notbold">{{$n(info.total_quantity.toFixed(2))}}</b-th>
                                                 <b-th class="notbold">{{$n(info.total_amount.toFixed(2))}}</b-th>
                                              </b-tr>
                                            </template>
                                            <template v-else>
                                              <b-tr>
                                                <b-td class="text-center" colspan="13"> {{ $t('globalTrans.noDataFound') }} </b-td>
                                              </b-tr>
                                            </template>
                                          </b-tbody>
                                      </b-table-simple>
                                    </b-col>
                                  </b-row>
                                </div>
                              </b-col>
                            </b-row>
                          </b-overlay>
                        </template>
                      </iq-card>
                    </b-col>
                  </b-row>
                </template>
              </iq-card>
            </b-overlay>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import RestApi, { incentiveGrantServiceBaseUrl } from '@/config/api_config'
import { millWiseDisbursementReport } from '../../api/routes'
import ListReportHead from '@/components/custom/ListReportHead.vue'
import ExportPdf from './pdf'

export default {
    components: {
      ListReportHead
    },
    data () {
        return {
          search: {
            fiscal_year_id: 0,
            mill_info_id: 0
          },
          totalResult: [],
          searchResult: {
            mill_info_id: '',
            mill_name: '',
            total_farmers: '',
            Fathers_name: '',
            Method_all_amount: 0,
            Method_four_amount: 0,
            Method_four_quantity: 0,
            Method_one_amount: '',
            Method_one_quantity: '',
            Method_three_amount: '',
            Method_three_quantity: '',
            Method_two_amount: '',
            Method_two_quantity: '',
            Total_land_quantity: 0,
            Village: 1,
            Wallet_no: null
          },
          searchResulttwo: {
            fiscal_year: '',
            fiscal_year_bn: '',
            mill_name: '',
            mill_name_bn: '',
            subzone_office_name: '',
            subzone_office_name_bn: '',
            center_name: '',
            center_name_bn: '',
            unit_name: '',
            unit_name_bn: ''
          },
          total: [],
          incentiveGrantServiceBaseUrl: incentiveGrantServiceBaseUrl,
          datas: [],
          grandTotal: {},
          loading: false,
          showData: false,
          childshowdata: false,
          cultivationMethod: [],
          subzoneOfficeList: [],
          centerList: [],
          unitList: []
        }
    },
    created () {
     this.unitList = this.getUnitList()
    },
    mounted () {
      core.index()
    },
    computed: {
      fiscalYearList: function () {
        return this.$store.state.commonObj.fiscalYearList.filter(item => item.status === 0)
      },
      millInfoList: function () {
        return this.$store.state.incentiveGrant.commonObj.millInfoList.filter(item => item.status === 1)
      }
    },
    watch: {
      'search.mill_info_id': function (newVal, oldVal) {
        if (newVal !== oldVal) {
          this.subzoneOfficeList = this.getSubzoneOfficeList(newVal)
        }
      },
      'search.subzone_office_id': function (newVal, oldVal) {
        if (newVal !== oldVal) {
          this.centerList = this.getCenterList(newVal)
        }
      }
    },
    methods: {
      pdfExport () {
        const reportTitle = this.$t('bsri_report.mill_disbursement_report')
        ExportPdf.exportPdfDetails(incentiveGrantServiceBaseUrl, '/common-config/report-heading/detail', 8, this.$i18n.locale, reportTitle, this.searchResult, this, this.search, this.searchResulttwo, this.grandTotal, this.total, this.totalResult)
      },
      async searchData () {
        if (this.search.fiscal_year_id) {
          const fiscalYearObj = this.$store.state.commonObj.fiscalYearList.find(fiscalYear => fiscalYear.value === parseInt(this.search.fiscal_year_id))
          this.searchResulttwo.fiscal_year = fiscalYearObj !== undefined ? fiscalYearObj.text_en : ''
          this.searchResulttwo.fiscal_year_bn = fiscalYearObj !== undefined ? fiscalYearObj.text_bn : ''
        }
        if (this.search.mill_info_id) {
          const millNameObj = this.$store.state.incentiveGrant.commonObj.millInfoList.find(millItem => millItem.value === parseInt(this.search.mill_info_id))
          this.searchResulttwo.mill_name = millNameObj !== undefined ? millNameObj.text_en : ''
          this.searchResulttwo.mill_name_bn = millNameObj !== undefined ? millNameObj.text_bn : ''
        }
        if (this.search.subzone_office_id) {
          const subzoneNameObj = this.$store.state.incentiveGrant.commonObj.subZoneList.find(subzoneItem => subzoneItem.value === parseInt(this.search.subzone_office_id))
          this.searchResulttwo.subzone_office_name = subzoneNameObj !== undefined ? subzoneNameObj.text_en : ''
          this.searchResulttwo.subzone_office_name_bn = subzoneNameObj !== undefined ? subzoneNameObj.text_bn : ''
        }
        if (this.search.center_id) {
          const centerObj = this.$store.state.incentiveGrant.commonObj.centerList.find(cntr => cntr.value === parseInt(this.search.center_id))
          this.searchResulttwo.center_name = centerObj !== undefined ? centerObj.text_en : ''
          this.searchResulttwo.center_name_bn = centerObj !== undefined ? centerObj.text_bn : ''
        }
          if (this.search.unit_id) {
          const UnitObj = this.$store.state.incentiveGrant.commonObj.unitList.find(cntr => cntr.value === parseInt(this.search.unit_id))
          this.searchResulttwo.unit_name = UnitObj !== undefined ? UnitObj.text_en : ''
          this.searchResulttwo.unit_name_bn = UnitObj !== undefined ? UnitObj.text_bn : ''
        }

        this.cultivationMethod = this.customCultivationInfo()
        this.loading = true
        this.showData = true
        this.childshowdata = false
        const params = Object.assign({}, this.search)
        await RestApi.getData(incentiveGrantServiceBaseUrl, millWiseDisbursementReport, params).then(response => {
          if (response.success) {
            if (response.data) {
              this.datas = this.getRelationData(response.data)
              this.grandTotal = response.grand_total
              this.searchResult = response.data
              this.loading = false
              this.childshowdata = true
              this.total = response.total
              let Oneamount = 0
              let Onequantity = 0
              let Twoamount = 0
              let Twoquantity = 0
              let Threeamount = 0
              let Threequantity = 0
              let Fouramount = 0
              let Fourquantity = 0
              let Totalfarmers = 0
                   response.data.forEach(function (item) {
                   Oneamount += parseFloat(item.Method_one_amount)
                   Onequantity += parseFloat(item.Method_one_quantity)
                   Twoamount += parseFloat(item.Method_two_amount)
                   Twoquantity += parseFloat(item.Method_two_quantity)
                   Threeamount += parseFloat(item.Method_three_amount)
                   Threequantity += parseFloat(item.Method_three_quantity)
                   Fouramount += parseFloat(item.Method_four_amount)
                   Fourquantity += parseFloat(item.Method_four_quantity)
                   Totalfarmers += parseFloat(item.total_farmers)
              })
              const obj = {
                          One_amount: Oneamount,
                          One_quantity: Onequantity,
                          Two_amount: Twoamount,
                          Two_quantity: Twoquantity,
                          Three_amount: Threeamount,
                          Three_quantity: Threequantity,
                          Four_amount: Fouramount,
                          Four_quantity: Fourquantity,
                          total_amount: Oneamount + Twoamount + Threeamount + Fouramount,
                          total_quantity: Onequantity + Twoquantity + Threequantity + Fourquantity,
                          total_farmers: Totalfarmers
              }
              this.totalResult = []
              this.totalResult.push(obj)
            }
          } else {
            this.datas = []
            this.grandTotal = {}
          }
        })
      },
      getRelationData (data) {
        const centerStore = this.$store.state.incentiveGrant.commonObj.centerList.filter(item => item.status === 1)

        const listData = data.map(item => {
          const centerObj = centerStore.find(cntr => cntr.value === item.center_id)

          const centerData = {
            center_name: centerObj !== undefined ? centerObj.text_en : ' ',
            center_name_bn: centerObj !== undefined ? centerObj.text_bn : ' '
          }
          return Object.assign({}, item, centerData)
        })

        return listData
      },
      customCultivationInfo () {
        const cultivationPolicyList = this.$store.state.incentiveGrant.commonObj.cultivationPolicy.filter(item => item.fiscal_year_id === this.search.fiscal_year_id)
        const listData = cultivationPolicyList.map(item => {
          const cultivationMethodObject = this.$store.state.incentiveGrant.commonObj.cultivationMethodList.find(methodItem => methodItem.value === item.method_cultivation_id)

          const cultivationMethodData = {
            cultivationMethod_name: cultivationMethodObject !== undefined ? cultivationMethodObject.text_en : ' ',
            cultivationMethod_name_bn: cultivationMethodObject !== undefined ? cultivationMethodObject.text_bn : ' '
          }
          return Object.assign({}, item, cultivationMethodData)
        })

        return listData
      },
      getSubzoneOfficeList (millInfoId) {
        return this.$store.state.incentiveGrant.commonObj.subzoneOfficeList.filter(item => item.status === 1 && item.mill_info_id === millInfoId)
      },
      getCenterList (subZoneOfficeId) {
        return this.$store.state.incentiveGrant.commonObj.centerList.filter(item => item.status === 1 && item.subzone_office_id === subZoneOfficeId)
      },
      getUnitList () {
        return this.$store.state.incentiveGrant.commonObj.unitList.filter(item => item.status === 1)
      }
    }
}
</script>
<style>
th {
 border: 1px solid black!important;
 font-size:12px!important;
}
.notbold{
  font-weight:normal;
  text-align: center;
}
</style>
